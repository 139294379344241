<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
	<Teleport to="body">
		<EasyContactButton @click.prevent="toggleModal" :button-text="buttonText" :active="isModalOpened" />

		<Transition :name="isMobile ? 'fade-up' : 'fade'">
			<Modal :show="isModalOpened" data-testid="easy-contact-modal" class="c-easy-contact-widget__modal">
				<template #content>
					<div class="c-easy-contact-widget__modal-content">
						<Transition name="fade">
							<div v-if="currentView === VIEWS.init" class="c-easy-contact-widget__init-view">
								<template v-if="chatIsOpening">
									<div class="spinner-wrapper">
										<Loader />
									</div>
								</template>
								<template v-else>
									<slot />
									<div class="c-easy-contact-widget__actions">
										<slot
											name="actions"
											:request-free-call="goToFreeCallView"
											:open-chat="handleOpenChat"
										/>
									</div>
								</template>
							</div>
							<div v-else-if="currentView === VIEWS.call" class="c-easy-contact-widget__call-view">
								<DsButton
									@click.prevent="currentView = VIEWS.init"
									class="back-btn"
									size="sm"
									aria-label="Back button"
									iconStart="navigate-back"
									variant="ghost"
								>
									{{ backButtonText }}
								</DsButton>
								<slot name="call-back-request-form" :submit-handler="onFormSubmit" />
								<DsButton variant="link" :href="linkButtonProps?.url">
									{{ linkButtonProps.label }}
								</DsButton>
							</div>
							<div class="c-easy-contact-widget__result-view" v-else-if="currentView === VIEWS.result">
								<div class="icon-wrapper">
									<DsIcon :icon-name="modalContent?.success ? 'checkmark' : 'close'" size="xl" />
								</div>
								<DsText html-element="h2" text-type="heading-2xl" text-style="bold">
									{{ modalContent?.successMessageTitle }}
								</DsText>
								<DsText
									html-element="p"
									text-type="body-md"
									v-html="modalContent?.successMessageDescription"
								/>

								<DsButton
									v-if="modalContent?.closeButtonText"
									variant="primary"
									@click="closeModal"
									data-testid="close-btn"
								>
									{{ modalContent?.closeButtonText }}
								</DsButton>
							</div>
						</Transition>
					</div>
				</template>
			</Modal>
		</Transition>
	</Teleport>
</template>

<script setup lang="ts">
import type { FormValues } from 'shared/composables/useFormKitHelpers.ts';
import { dsUseIsMobile, DsButton, DsIcon, DsText } from 'coloplast-design-system';
import { ref } from 'vue';
import { useFormKitHelpers } from 'shared/composables/useFormKitHelpers.ts';
import { useZendeskChat } from './composables/chatZendesk';
import { useGenesysChat } from './composables/chatGenesys';
import EasyContactButton from './easyContactButton.vue';
import Loader from 'shared/components/Loader.vue';
import Modal from 'shared/components/modal/modal.vue';
import './easy-contact-widget.scss';

/**
 * @enum CHAT_PROVIDERS
 * These values correspond to BE enums stored in Enums/ChatProviderTypes.cs
 */
enum CHAT_PROVIDERS {
	Zendesk = 'Zendesk',
	Genesys = 'Genesys',
}

enum VIEWS {
	init = 'init',
	call = 'call',
	result = 'result',
}

interface Props {
	buttonText: string;
	headline: string;
	backButtonText: string;
	chatProviderId: string;
	chatEnabled?: boolean;
	forceNativeChatButtonVisible?: boolean;
	linkButtonProps: {
		label: string;
		url?: string;
	};
}

interface EasyContactWidgetFormResponse {
	displayPopupMessage: boolean;
	successMessageTitle: string;
	successMessageDescription: string;
	successMessageTimeout: number;
	displayFormNextTime: boolean;
	closeButtonText: string;
}

const { chatProviderId, chatEnabled, forceNativeChatButtonVisible } = withDefaults(defineProps<Props>(), {
	buttonText: 'Contact us',
	headline: 'Do you need help?',
	backButtonText: 'back',
	linkButtonProps: () => ({
		label: 'Schedule appointment',
	}),
	chatEnabled: true,
});

const currentView = ref(VIEWS.init);
const isModalOpened = ref(false);
const chatIsOpening = ref(false);
let isChatOpened = ref<boolean>();

const modalContent = ref<
	EasyContactWidgetFormResponse & {
		success: boolean;
	}
>();

let openChat: () => boolean = () => false;

if (chatEnabled) {
	const chatOptions = {
		openPopupCallback: () => handleChatPopupOpen(),
		forceNativeChatButtonVisible,
	};
	switch (chatProviderId) {
		case CHAT_PROVIDERS.Genesys:
			({ openChat, isChatOpened } = useGenesysChat(chatOptions));
			break;
		case CHAT_PROVIDERS.Zendesk:
			({ openChat, isChatOpened } = useZendeskChat(chatOptions));
			break;
	}
}

const { isMobile } = dsUseIsMobile();
const { submitForm, prepareFormData } = useFormKitHelpers();

function goToFreeCallView() {
	currentView.value = VIEWS.call;
}

function toggleModal() {
	if (isModalOpened.value) {
		closeModal();
	} else {
		openModal();
	}
}

function openModal() {
	isModalOpened.value = true;
}

function closeModal() {
	isModalOpened.value = false;
	chatIsOpening.value = false;
	currentView.value = VIEWS.init;
}

function handleOpenChat() {
	if (isChatOpened.value) {
		closeModal();
		return;
	}
	chatIsOpening.value = true;

	const wasOpened = openChat();

	if (!wasOpened) {
		closeModal();
		chatIsOpening.value = false;
	}
}

function handleChatPopupOpen() {
	chatIsOpening.value = false;
	closeModal();
}

async function onFormSubmit(formValues: FormValues) {
	const payload = prepareFormData(formValues);

	try {
		const { data, status } = await submitForm<EasyContactWidgetFormResponse>(payload);

		modalContent.value = {
			...data,
			success: status === 200,
		};

		currentView.value = VIEWS.result;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
</script>
