<template>
	<DsButton
		class="c-easy-contact-widget__btn"
		variant="primary"
		iconStart="chat"
		:class="{
			'is-mobile': isMobile,
			'is-active': active,
			'animate-enter': animateEnter,
		}"
		data-testid="easy-contact-btn"
	>
		<DsIcon icon-name="chevron-down" size="md" />
		<p>{{ buttonText }}</p>
	</DsButton>
</template>

<script setup lang="ts">
import { dsUseIsMobile, DsButton, DsIcon } from 'coloplast-design-system';

const WIDGET_RUNNING_SESSION_VAR = 'cp-easy-call-widget-running';

defineProps({
	buttonText: {
		type: String,
		default: 'Contact us',
	},
	active: {
		type: Boolean,
		default: false,
	},
});

const performanceEntry = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
const isReloadType = performanceEntry.type === 'reload';

const isAlreadyRunning = !!sessionStorage.getItem(WIDGET_RUNNING_SESSION_VAR);

const animateEnter = isReloadType || !isAlreadyRunning;

if (!isAlreadyRunning) {
	sessionStorage.setItem(WIDGET_RUNNING_SESSION_VAR, 'true');
}

const { isMobile } = dsUseIsMobile();
</script>
