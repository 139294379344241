import { ref } from 'vue';

interface UseZendeskOptions {
	openPopupCallback?: () => void;
	closePopupCallback?: () => void;
}

const CHAT_OPENED_SESSION_VAR = 'cp-chat-opened';

const messages = {
	noScript: '[chatZendesk]: No zendesk script snippet found',
	incorrectContext: '[chatZendesk]: No correct Zendesk widget context is available',
	noChatWrapper: '[chatZendesk]: Chat wrapper was not found. Window opening will not be tracked',
} as const;

const performanceEntry = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
const isReloadType = performanceEntry.type === 'reload';

const useZendeskOptions = ref<UseZendeskOptions>();
let zendeskScript: HTMLScriptElement | undefined;
const isWindowObserverSet = ref(false);
const isZendeskSet = ref(false);
const isChatOpened = ref(false);

// Prevents from reopening on page-refresh
if (isReloadType) {
	sessionStorage.removeItem(CHAT_OPENED_SESSION_VAR);
}

const chatWasOpened = !!sessionStorage.getItem(CHAT_OPENED_SESSION_VAR);

function openChat() {
	setupZendesk(useZendeskOptions.value);

	if (!window.$zopim) {
		return false;
	}
	$zopim.livechat.window.show();
	return true;
}

export function reopenOnReload() {
	if (chatWasOpened) {
		openChat();
	}
}

export function setupZendesk(options?: UseZendeskOptions) {
	if (zendeskScript) {
		setupChatPopupObserver({ ...options });
	} else {
		console.warn(messages.noScript);
		return;
	}

	if (isZendeskSet.value) return;

	if (!window.$zopim) {
		console.warn(messages.incorrectContext);
		return;
	}

	$zopim.livechat.button.hide();

	$zopim.livechat.window.onShow(() => {
		isChatOpened.value = true;
		sessionStorage.setItem(CHAT_OPENED_SESSION_VAR, 'true');
	});

	$zopim.livechat.window.onHide(() => {
		isChatOpened.value = false;
		sessionStorage.removeItem(CHAT_OPENED_SESSION_VAR);
	});

	isZendeskSet.value = true;
}

function setupChatPopupObserver({ openPopupCallback = () => {}, closePopupCallback = () => {} }) {
	if (isWindowObserverSet.value) return;

	const chatNativeButton = document.getElementById('launcher');
	const chatWrapper = chatNativeButton?.parentNode;

	if (!chatWrapper) {
		console.warn(messages.noChatWrapper);
		return;
	}

	const config = { childList: true };

	const callback = (mutationList: MutationRecord[]) => {
		for (const mutation of mutationList) {
			if (mutation.type === 'childList') {
				const addedNodes = Array.from(mutation.addedNodes) as HTMLElement[];
				const removedNodes = Array.from(mutation.removedNodes) as HTMLElement[];

				if (addedNodes.find((node) => node.id === 'webWidget')) {
					openPopupCallback();
				}

				if (removedNodes.find((node) => node.id === 'webWidget')) {
					closePopupCallback();
				}
			}
		}
	};

	const observer = new MutationObserver(callback);

	observer.observe(chatWrapper, config);

	isWindowObserverSet.value = true;
}

/* eslint-disable max-len */
/**
 * Composable which implements the Zendesk live-chat integration
 *
 * **Resources:**
 *
 * [chat-api](https://developer.zendesk.com/api-reference/widget/chat-api/)
 *
 * [Adding a click-to-chat link](https://support.zendesk.com/hc/en-us/community/posts/4409506836890-Adding-a-click-to-chat-link)
 *
 * [Javascript API] https://api.zopim.com/files/meshim/widget/controllers/liveChatAPI/Window-js.html
 */
/* eslint-enable max-len */
export const useZendeskChat = (options?: UseZendeskOptions) => {
	useZendeskOptions.value = options;
	zendeskScript = document.querySelector('script#ze-snippet') as HTMLScriptElement | undefined;

	function init() {
		setupZendesk(useZendeskOptions.value);
		reopenOnReload();
	}

	if (document.readyState == 'complete') {
		init();
	} else {
		window.addEventListener('load', () => {
			init();
		});
	}

	return {
		openChat,
		chatAvailable: isZendeskSet,
		isChatOpened,
	};
};
